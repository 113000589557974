<template>

    <a-layout style="height:100%;">
        <a-layout-content style="height:100%;">
            <div class="yj-conten" style="background-color:#fff;width:calc(100% - 32px);padding-top:20px; min-width:fit-content;min-height:calc(100% - 48px)">
                <a-layout style="height:100%;background-color:#fff">
                    <!--<a-layout-header style="background:#fff;padding:0 20px;">
                        <a-radio-group default-value="b" @change="onRadioChange">
                            <a-radio-button value="a">
                                基本信息
                            </a-radio-button>
                            <a-radio-button value="b">
                                常见问题
                            </a-radio-button>
                            <a-radio-button value="c">
                                地点信息
                            </a-radio-button>
                        </a-radio-group>
                    </a-layout-header>-->
                    <a-layout-content style="background-color:#fff;padding:20px 20px 16px   ;  width: max-content;">
                        <a-form-model ref="ruleForm" :rules="rules" :model="formModel">
                            <a-row>
                                <a-col :span="15">
                                    <a-form-item label="问题描述" style="margin-bottom:0px;"
                                                 :label-col="formItemLayout.labelCol"
                                                 :wrapper-col="formItemLayout.wrapperCol">

                                        <a-form-model-item has-feedback prop="title">
                                            <a-textarea id="title" v-model="formModel.title"
                                                        placeholder="请输入问题描述"
                                                        :auto-size="{ minRows:3, maxRows: 5 }" />
                                        </a-form-model-item>
                                    </a-form-item>
                                    <a-form-item label="问题解答" style="margin-bottom:0px;"
                                                 :label-col="formItemLayout.labelCol"
                                                 :wrapper-col="formItemLayout.wrapperCol">
                                        <a-form-model-item has-feedback prop="brief">
                                            <Rich v-bind:value.sync="formModel.brief" style="width:800px;"></Rich>
                                        </a-form-model-item>

                                    </a-form-item>

                                </a-col>
                            </a-row>

                        </a-form-model>
                    </a-layout-content>
                    <a-layout-footer style="padding: 0 0 16px 0 ;background-color:#fff;">
                        <a-row  type="flex">
                            <a-col :span="12">
                                <a-row typeof="flex" justify="space-around" class="textal_r">
                                    <a-col :span="12">
                                        <a-button  size="large"  type="primary" @click="updateQuestionDetail('ruleForm')">保存</a-button>
                                    </a-col> <a-col :span="6">
                                        <a-button size="large" centered Vertically @click="cancel()">取消</a-button>

                                    </a-col>

                                </a-row>
                            </a-col>
                        </a-row>
                    </a-layout-footer>
                </a-layout>

            </div>

        </a-layout-content>


    </a-layout>

</template>


<script type="text/javascript">

    import http from "@/Plugin/Http.js";
    import Rich from "@/components/RichEditor.vue";
    import { message } from 'ant-design-vue'
    export default {
        name: "SiteDetail",
        components: {
            Rich
        },
        data() {
            return {
                formModel: {
                    id: this.$route.query.id,
                    title: "",
                    brief: "",
                    customerID: "",
                },

                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 3 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 21 },
                    },
                },
                rules: {
                    title: { validator: this.validateUserName, trigger: 'change', required: true },
                    brief: { validator: this.validateUserName, trigger: 'change', required: true },
                }
            };
        },

        methods: {

            onRadioChange: function (data) {
                var self = this;
                var val = data.target.value;
                if (val == 'a') {
                    self.$router.push({ name: "ShopManage_BaseInfor" });
                } else if (val == 'c') {
                    self.$router.push({ name: "ShopManage_SiteList" });
                }
            },
            validateUserName: function (rule, value, callback) {
                console.log(value);
                if (value === '' || value === undefined) {
                    callback(new Error('该字段不能为空'));
                } else {
                    callback();
                }
            },
            updateQuestionDetail: function (formName) {
                var self = this;
                this.$refs[formName].validate(valid => {
                    console.log(valid);
                    if (valid) {
                        var op = {
                            url: "/ShopModule/ShopManage/UpdateShopQuestion",
                            data: {
                                ID: self.formModel.id,
                                Title: self.formModel.title,
                                AnswerText: self.formModel.brief
                            },
                            OnSuccess: function (data) {

                                self.modelDisplay = false
                                if (data.data == "True") {

                                    var display = "新增成功";
                                    if (self.formModel.id != null && self.formModel.id != "") {
                                        display = "编辑成功";
                                    }

                                    message.success(
                                        display,
                                        1.5,
                                        function () {
                                            self.$router.push({
                                                name: "ShopManage_ShopManage", query: {
                                                    showName: "QuestionList"
                                                }
                                            })
                                        }
                                    );
                                }









                            }
                        };
                        //console.log(op);
                        http.Post(op);
                    } else {
                        return false;
                    }
                });
            },
            getData: function () {
                var self = this;
                var op = {
                    url: "/ShopModule/ShopManage/GetShopQuestionDetail",
                    data: {
                        id: self.formModel.id
                    },
                    OnSuccess: function (data) {
                        console.log(data);
                        //初始化问题
                        self.formModel.title = data.data.Title;
                        self.formModel.brief = data.data.AnswerText;
                    }
                };
                http.Post(op);
            },
            save: function (formName) {
                var self = this;
                this.$refs[formName].validate(valid => {
                    if (valid) {


                        var op = {
                            url: "/ShopModule/ShopManage/UpdateSite",
                            data: {

                            },
                            OnSuccess: function (data) {

                                if (data.data == "True") {

                                    var display = "新增成功";
                                    if (self.id != null && self.id != "") {
                                        display = "编辑成功";
                                    }

                                    message.success(
                                        display,
                                        1.5,
                                        function () {
                                            self.$router.push({
                                                name: "ShopManage_QuestionList", query: {

                                                }
                                            })
                                        }
                                    );
                                }
                            }
                        };
                        console.log(op);
                        // http.Post(op);
                    } else {
                        return false;
                    }
                });
            },
            cancel() {
                var self = this;
                self.$router.push({
                    name: "ShopManage_QuestionList"
                });
            }
        },
        mounted() {
            var self = this;
            console.log(self.formModel.id);
            if (self.formModel.id != "" && self.formModel.id != undefined)
                self.getData();
        }

    };
</script>



